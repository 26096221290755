import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = props => (
  <Layout pathContext={props.pathContext}>
    <SEO title="404: Not found" />
    <div style={{maxWidth: "320px", margin: "2rem auto" }}>
      <h1>The Page was not found</h1>
      <p>
        Whoops, it seems the page you are looking for is not availbale
      </p>
      <Link to="/">
        Back to Virtulleum
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
